<script>
import appConfig from "@/app.config";
import Switches from "vue-switches";

export default {
  props: ["summary", "bestSummary"],
  data() {
    return {
      userRole: "",
      series: [],
      chartOptions: {
        chart: {
          height: 230,
          type: "donut",
          toolbar: {
            show: true,
          },
        },
        labels: Object.keys(appConfig.sub_statuses),
        plotOptions: {
          pie: {
            donut: {
              size: "60%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: Object.values(appConfig.sub_statuses),
      },
      emptyChatOptions: {
        chart: {
          height: 230,
          type: "donut",
        },
        labels: ["No Data Found"],
        plotOptions: {
          pie: {
            donut: {
              size: "60%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#e0e0e0"],
      },
      total_doctors_contacted: 0,
      total_calls: 0,
      key: 0,
      key1: 0,
      statuses : appConfig.sub_statuses,
      show_sub_statuses : false,
      bestStatuses : appConfig.sub_statuses,
      show_best_statuses: false,
    };

  },
  components: {
    Switches
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.$store.commit("setRole",user.role);
  },
  watch: {
    show_sub_statuses(v) {
      if(v == true){
        if(this.show_best_statuses == true){
          this.bestStatuses = appConfig.statuses;
          this.chartOptions.labels = Object.keys(this.bestStatuses);
          this.chartOptions.colors = Object.values(this.bestStatuses);
          this.loadData2(this.bestSummary);
        }else{
          this.statuses = appConfig.statuses;
          this.chartOptions.labels = Object.keys(this.statuses);
          this.chartOptions.colors = Object.values(this.statuses);
          this.loadData(this.summary);
        }
      }else{
        if(this.show_best_statuses == true){
          this.bestStatuses = appConfig.sub_statuses;
          this.chartOptions.labels = Object.keys(this.bestStatuses);
          this.chartOptions.colors = Object.values(this.bestStatuses);
          this.loadData2(this.bestSummary);
        }else{
          this.statuses = appConfig.sub_statuses;
          this.chartOptions.labels = Object.keys(this.statuses);
          this.chartOptions.colors = Object.values(this.statuses);
          this.loadData(this.summary);
        }
      }
    },
    show_best_statuses(v) {
      if(v == true){
        if(this.show_sub_statuses == true){
          this.bestStatuses = appConfig.statuses
        }else{
          this.bestStatuses = appConfig.sub_statuses;
        }
        this.chartOptions.labels = Object.keys(this.bestStatuses);
        this.chartOptions.colors = Object.values(this.bestStatuses);
        this.loadData2(this.bestSummary);
      }else if(v == false){
        if(this.show_sub_statuses == true){
          this.statuses = appConfig.statuses;
          this.loadData(this.summary)
        }else{
          this.statuses = appConfig.sub_statuses;
          this.loadData(this.summary)
        }
      }



      // if(v == true){
      //   this.bestStatuses = appConfig.sub_statuses;
      //   if (this.show_sub_statuses == true) {
      //     this.bestStatuses = appConfig.statuses;
      //   }
      // }else{
      //   this.bestStatuses = appConfig.statuses;
      // }
      
    },
    summary: {
      deep: true,
      handler(v) {
       this.loadData(v);
      },
    }
  },
  methods: {
    loadData(v){
       this.total_calls = 0;
        this.series = [0];
        if (!v || typeof v.length == 'undefined' || v.length <= 0) {
          return;
        }
        this.series = [];
        let _this = this;
        Object.keys(this.statuses).forEach((key) => {
            let data = v.filter((d) => (this.show_sub_statuses == true ? d.product_call_status : d.product_call_sub_status) === key);
            if (data && data.length > 0) {
              _this.total_calls += data.map(d => d.total_calls).reduce((a,b) => a+b,0);
                _this.series.push(data.map(d => d.total_calls).reduce((a,b) => a+b,0))
            } else {
              _this.series.push(0);
            }
        });
        this.key1 += 1;
        this.key += 1;
    },
    loadData2(v) {
      this.total_doctors_contacted = 0;
      this.series = [0];
      if (!v || typeof v.length == 'undefined' || v.length <= 0) {
        return;
      } 
      this.series = [];
      let _this = this;
      Object.keys(this.bestStatuses).forEach((key) => {
          let data = v.filter((d) => {
            if(this.show_best_statuses == true && this.show_sub_statuses == true){
              return d.display_key === key;
            }
            return d.sub_status_display_key === key;
          });
          if (data && data.length > 0) {
            _this.total_doctors_contacted += data.map(d => d.total_doctors_contacted).reduce((a,b) => a+b,0);
              _this.series.push(data.map(d => d.total_doctors_contacted).reduce((a,b) => a+b,0))
          } else {
            _this.series.push(0);
          }
      });
      this.key1 += 1;
      this.key += 1;
    },
    getPercentage(i) {
      if (this.series[i]) {
        if(this.show_best_statuses == true){
          return ((this.series[i] / this.total_doctors_contacted) * 100).toFixed(2);
        }
        return ((this.series[i] / this.total_calls) * 100).toFixed(2);
      }
      return 0;
    },
    updateText(s) {
      return s.toLowerCase();
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Call Status Analytics</h4>
      <div class="d-flex justify-content-between align-items-center">
          <template v-if="$store.state.role == 'AnalyticsSuperAdmin' ">
            <div class="d-flex overall-count">
              <div class="mr-2">Detailed Statuses</div>
              <switches v-model="show_sub_statuses" type-bold="true" color="success" class="m-0"></switches>
            </div>
          </template>
          <template>
            <div class="d-flex overall-count">
              <div class="mr-2">Best Call Statuses</div>
              <switches v-model="show_best_statuses" type-bold="true" color="success" class="m-0"></switches>
            </div>
          </template>
        </div>
      <div id="donut-chart" class="apex-charts"></div>
     
      <apexchart
        
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key1"
      ></apexchart>
      <div class="row">
        <div class="col-4" v-for="(c, i) in chartOptions.labels" :key="i">
          <div class="text-center mt-4">
            <p class="mb-2" style="text-transform: capitalize">
              <i
                class="mdi mdi-circle font-size-10 mr-1"
                :style="
                  'color: ' +
                  (chartOptions.colors[i] != null
                    ? chartOptions.colors[i]
                    : '#e0e0e0')
                "
              ></i>
              {{ updateText(c) }}
            </p>
            <h5>{{ getPercentage(i) }} %</h5>
          </div>
        </div>
        <!--        <div class="col-4">-->
        <!--          <div class="text-center mt-4">-->
        <!--            <p class="mb-2 text-truncate">-->
        <!--              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Product B-->
        <!--            </p>-->
        <!--            <h5>26 %</h5>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-4">-->
        <!--          <div class="text-center mt-4">-->
        <!--            <p class="mb-2 text-truncate">-->
        <!--              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> Product C-->
        <!--            </p>-->
        <!--            <h5>42 %</h5>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
