<script>
/**
 * Widget Component
 */

import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
import moment from "moment";

export default {
  //   props : ['projects'],
  components: {
    Multiselect,
    Switches,
    DatePicker,
  },
  data() {
    return {
      filters: {
        project: null,
        startDate: null,
        endDate: null,
        productCallStatus: null,
        speciality: null,
        country: null,
        state: null,
        city: null,
        opted_in: 0,
        daterange: null,
      },
      projects: [],
      specialities: [],
      states: [],
      cities: [],
      countries: ["India"],
    };
  },
  methods: {
    async getProjects() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/projects"
        );
        this.projects = response.data.telecaller_projects;
      } catch (err) {
        console.log(err);
      }
    },
    async getSpecialities() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/specialities"
        );
        this.specialities = response.data.specialities;
      } catch (err) {
        console.log(err);
      }
    },
    async loadFilters() {
      this.getProjects();
      this.getSpecialities();
      this.getStates(this.countries);
    },
    async getStates(countries) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/states",
          {
            params: {
              countries: countries,
            },
          }
        );
        this.states = response.data.states;
      } catch (err) {
        console.log(err);
      }
    },
    async getCities(states) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/cities",
          {
            params: {
              states: states.map((obj) => obj.name),
            },
          }
        );
        this.cities = response.data.cities;
      } catch (err) {
        console.log(err);
      }
    },
    getFormatedDate(date) {
      try {
        return moment(date).format("yyyy-mm-dd");
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  },
  mounted() {
    this.loadFilters();
  },
  watch: {
    filters: {
      deep: true,
      handler(v) {
        this.$emit("filters", v);
      },
    },
    "filters.state"(v) {
      this.getCities(v);
    },
  },
};
</script>

<template>
  <!-- <div class="card">
        <div class="card-header bg-white">
            <h2>Filters</h2>
        </div>
        <div class="card-body"> -->
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>Project</label>
        <multiselect
          v-model="filters.project"
          :show-labels="false"
          :close-on-select="false"
          :options="projects"
          track-by="id"
          label="title"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Speciality</label>
        <multiselect
          v-model="filters.speciality"
          :show-labels="false"
          :options="specialities"
          :close-on-select="false"
          track-by="title"
          label="title"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>State</label>
        <multiselect
          v-model="filters.state"
          :options="states"
          :show-labels="false"
          :close-on-select="false"
          track-by="name"
          label="name"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>City</label>
        <multiselect
          v-model="filters.city"
          :options="cities"
          :show-labels="false"
          :close-on-select="false"
          track-by="name"
          label="name"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group mb-3">
        <label>Date Range</label>
        <br />
        <date-picker
          v-model="filters.daterange"
          range
          append-to-body
          lang="en"
          confirm
        ></date-picker>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Consent Doctor</label>
        <div class="mb-3 pt-2">
          <switches
            v-model="filters.opted_in"
            type-bold="true"
            color="success"
            class="mb-0"
          ></switches>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
</div> -->
  <!-- end row -->
</template>

<!--// Object.keys(appConfig.statuses).forEach((key) => {-->
<!--//   let data = v.filter(d => d.product_call_status === key);-->
<!--//   if(data[0]){-->
<!--//     _this.total_calls += parseInt(data[0].total_calls);-->
<!--//     _this.series.push(data[0].total_calls);-->
<!--//   }-->
<!--// });-->
