<script>
/**
 * Transactions component
 */
import appConfig from "@/app.config";

export default {
  props: ["filters"],
  data() {
    return {
      transactionData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "total_calls",
      sortDesc: true,
      fields: [
        { key: "speciality", sortable: true, label: "Speciality" },
        { key: "total_calls", sortable: true, label: "Calls Made" },
        {
          key: "total_doctors_contacted",
          sortable: true,
          label: "Doctors Contacted",
        },
        {
          key: "avg_call_duration",
          sortable: true,
          label: "Avg Call Duration",
        },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler(v) {
        if(Object.keys(v).length > 0);
        {
          this.getTableData();
        }
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.transactionData.length;
    // this.getTableData();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getTableData() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/specialityCallsSummary",
          {
            params: this.filters,
          }
        );
        this.transactionData = response.data.speciality_call_summary;
        this.transactionData.map((t) => {
          t.avg_call_duration = (t.total_call_duration / t.total_calls).toFixed(
            2
          );
          t.avg_call_duration =
            (!isNaN(t.avg_call_duration) ? t.avg_call_duration : 0) + " sec";
          t.total_call_duration = t.total_call_duration + " sec";
        });
        this.totalRows = this.transactionData.length;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <!--      <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">-->
      <!--        <template v-slot:button-content>-->
      <!--          <i class="mdi mdi-dots-vertical"></i>-->
      <!--        </template>-->
      <!--        &lt;!&ndash; item&ndash;&gt;-->
      <!--        <b-dropdown-item>Sales Report</b-dropdown-item>-->
      <!--        &lt;!&ndash; item&ndash;&gt;-->
      <!--        <b-dropdown-item>Export Report</b-dropdown-item>-->
      <!--        &lt;!&ndash; item&ndash;&gt;-->
      <!--        <b-dropdown-item>Profit</b-dropdown-item>-->
      <!--        &lt;!&ndash; item&ndash;&gt;-->
      <!--        <b-dropdown-item>Action</b-dropdown-item>-->
      <!--      </b-dropdown>-->

      <h4 class="card-title mb-4">Speciality Call Summary</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="transactionData"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(speciality)="row">
            <div>{{ row.value != "" ? row.value : "Unknown" }}</div>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
