<script>
import appConfig from "@/app.config";

export default {
    props: ['filters'],
    data() {
        return {
            optinStatuses: {
                opted_in : 0,
                not_opted_in: 0,
                disagree: 0,
            },
        };
    }, 
    methods: {
        async getOptInStatuses() {
            try {
                if(this.$store.state.authfack.user.role != 'AnalyticsSuperAdmin') {
                const response = await this.$http.get(
                    appConfig.api_base_url + "/limitedaccess/sunpharma-optin-statuses",{
                        params : this.filters
                    }
                );
                this.optinStatuses = response.data;
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
    mounted() {
        this.getOptInStatuses();
    },
    watch: {
        filters: {
        deep: true,
        handler() {
            this.getOptInStatuses();
        },
        },
    },
};
</script>
<template>
    <div class="card">
        <div class="d-flex">
            <div class="card-body border-right p-2">
            <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">{{ optinStatuses.opted_in }}</h3>
                <div class="text-success"><i class="ri ri-whatsapp-fill" style="font-size: 25px;"></i></div>
            </div>
            <h4 class="card-title mb-0" style="align-self: center;">Opted In</h4>  
        </div>
        <div class="card-body border-right p-2">
            <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">{{ optinStatuses.not_opted_in }}</h3>
                <div class="text-warning"><i class="ri ri-whatsapp-fill" style="font-size: 25px;"></i></div>
            </div>
            <h4 class="card-title mb-0" style="align-self: center;">Not Yet Opted In</h4>
        </div>
        <div class="card-body border-right p-2">
            <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">{{ optinStatuses.disagree }}</h3>
                <div class="text-secondary"><i class="ri ri-whatsapp-fill" style="font-size: 25px;"></i></div>
            </div>
            <h4 class="card-title mb-0" style="align-self: center;">Disagreed</h4>   
        </div>
        </div>
        
    </div>
</template>

